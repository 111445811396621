/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton, Popover, PopoverProps, PopoverVirtualElement, Tooltip } from '@mui/material';
import React, { FC, useEffect, useMemo, useRef, useState, KeyboardEvent } from 'react';
import './SidePopover.css';
import { Icon, IconWithTooltip } from '../globalIcons';
import { CustomButtonGroup, HorizontalNavbar, TableSubHeader } from '../globalExports';
import { HorizontalNavbarPropTypes } from '../HorizontalNavbar/HorizontalNavbar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


interface IFormButtonType {
  buttonText: string,
  buttonId: string,
  btnClassName: string,
  handleClick: ((event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => void) | undefined,
  isDisabled: boolean,
  buttonIconClassName?: string,
  buttonVariant: string, // 'filled' or 'outlined'
  customTheme?: unknown,
}
interface SidePopoverProps {
  id: string;
  open: boolean;
  anchorEl?:
  | null
  | Element
  | (() => Element)
  | PopoverVirtualElement
  | (() => PopoverVirtualElement);
  handleClose: () => void;
  headerText?: string;
  headerSecondaryText?: string
  horizontalLinePopover?: boolean
  children: JSX.Element,
  btnsList?: IFormButtonType[]
  containerClassName?: string,
  popperClassName?: string
  headerClassName?: string;
  isRuleEnginePopOver?: boolean;
  ruleEngineSidePopoverOnclose?: ()=> void;
  navBarconfigurations?: HorizontalNavbarPropTypes,
  infoTitle?: null | string,
  headingIconClassName?: null | string,
  subHeadingIconClassName?: null | string,
  isSidePopOverExpanded?: null | boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleSidePopOverExpansion?: () => void,
  showExpandableIcon?:boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerActionBtns?: Array<any>,
  isEditButtonVisible?: boolean,
  onEditButtonClick?: ()=>void

}

const SidePopover: FC<SidePopoverProps> = (props) => {
  const { id, open, anchorEl, handleClose,popperClassName, headerText, children, btnsList, containerClassName,navBarconfigurations, headerSecondaryText,horizontalLinePopover,headerClassName,ruleEngineSidePopoverOnclose,isRuleEnginePopOver,infoTitle,headingIconClassName,isSidePopOverExpanded,handleSidePopOverExpansion,showExpandableIcon,subHeadingIconClassName,headerActionBtns,isEditButtonVisible,onEditButtonClick} = props;
  console.log(headerText,headerSecondaryText,'headerSecondaryText');
  
  return (
    <Popover
      className="side__popover__container"
      id={id}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      style={{ backgroundColor: isRuleEnginePopOver ? 'rgba(0,0,0,0.5)' : 'none'}}
      marginThreshold={0}
      classes={{ paper: 'side__popover__container-paper ' + popperClassName }}
    >
      <div className={'side__filter__wrap ' + containerClassName}>
        <div className={'side__filter__wrap-header ' + headerClassName}>
          {headerText && <div className='side__popover__icon__heading__wrapper'>
            {headerText && <div className="side__filter__wrap-header-text">{headerText}</div>}
            {headingIconClassName && <IconWithTooltip iconClassName={headingIconClassName??''} containerClassName={'side__popover__icon__heading__wrapper'}/>}
          </div>}
          {navBarconfigurations && <div className="rms__table__dashboard-navbar-wrap">
          <HorizontalNavbar
            selectedNav={navBarconfigurations?.selectedNav}
            onNavClick={(event, val) => { event?.stopPropagation(); navBarconfigurations?.onNavClick?.(val) }}
            navList={navBarconfigurations?.navList}
          />
        </div>}
          <div className="side__filter__wrap-icon">
            {isSidePopOverExpanded ?  <TableSubHeader headerActionBtns={headerActionBtns} /> : null}
            {isEditButtonVisible && <IconWithTooltip toolTipText = {'Edit'} iconClassName={'rms__pencil__icon'} onIconClick={()=>{ onEditButtonClick && onEditButtonClick()}}/>}
          {showExpandableIcon && <IconWithTooltip  toolTipText = {`${isSidePopOverExpanded ? 'Shrink' : 'Expand'}`} iconClassName={isSidePopOverExpanded ? 'side__popover__shrink__icon': 'side__popover__expand__icon'} onIconClick={()=>{
            handleSidePopOverExpansion && handleSidePopOverExpansion()
          } }/>}
            <IconWithTooltip
              iconClassName="filter__cancel__icon"
              toolTipText={'Close'}
              onIconClick={() => {
                isRuleEnginePopOver ? ruleEngineSidePopoverOnclose && ruleEngineSidePopoverOnclose() : handleClose()}}
            />
          </div>
        </div>
       {headerSecondaryText && <div className='secondary__text__header__wrapper'>
        <div className='side__filter__wrap-header-secondary-text'>{headerSecondaryText}</div>
        {subHeadingIconClassName && <IconWithTooltip iconClassName={subHeadingIconClassName??''} containerClassName={'action__control__side__popover__icon'}/>}
        {infoTitle &&<Tooltip title={infoTitle}>
                <IconButton sx={{ padding: 0, marginLeft: '2px' }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>}
        </div>}
       {horizontalLinePopover && <hr className="custom-hr" />}

        <div className="side__filter__wrap-content"  >
          {children && children}

        </div>
        {btnsList && <div className="side__filter__wrap-footer">
          <CustomButtonGroup
            buttonsList={btnsList ?? []}
            buttonGroupClassName={'button__group__footer'}
          />
        </div>}
      </div>
    </Popover>
  );
};

export default SidePopover;
