import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import React,{ FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarTheme } from '../../globalUtils/globalExports';
import { useScreenSize } from '../../globalUtils/globalHooks';
import { SidebarPropTypes } from '../AppLayout';
import SidebarItem from './SidebarItem';
import access from '../../auth/service/AccessControl';

const Sidebar: FC<SidebarPropTypes> = (props) => {
  const location = useLocation()

  const { expandSideBar, handleSidebarToggle } = props;
  const [selected, setSelected] = useState('/dashboard');
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const chargersRoutes = ['/manage-chargers','/add-charger','/charger-detail','/charger','/update-settings','/add-parameter','/view-parameter','/edit-parameter','/update-commands','/view-command','/add-command','/edit-command']
  const accessControlRoutes = ['/manage-users','/roles','/user','/role','/manage-org','/org']
  const customerRoutes = ['/customers','/customer','/edit-history'];
  const chargingStationRoutes = ['/charging-stations','/station']
  const reportsRoutes = ['/chargingHistory','/chargerLogs','/exceptionLogs'];
  const configureNotificationRoutes = ['/configureNotification','/create-notification-rule'];
  const ruleEngineRoutes = ['/rule-engine','/new-rule','/define-execution-plan'];
  const updateCommandRoutes = ['/add-command','/update-commands']


  const handleSelectedMenu = (value: string): void => {
    setSelected(value);
  };

  const handleOpenedMenu = (value: string | null): void => {    
    setOpenMenu(value);
  };


  const screenSize = useScreenSize?.();
  const handleDrawerClose = useCallback(() => {
    handleSidebarToggle?.();
  }, [handleSidebarToggle]);


  // This useEffect runs on every path change and sets the selected side bar item 
  // and open's the side bar nested menu if any..
  useEffect(() => {
    const currentPathname = location.pathname    
    if (currentPathname !== selected) {
      if (chargersRoutes.includes(currentPathname)) {
        handleOpenedMenu('Chargers');
        if (currentPathname === '/manage-chargers' || currentPathname === '/add-charger' || currentPathname === '/charger' || currentPathname === '/charger-detail') {
          setSelected('/manage-chargers')
        } else if (currentPathname === '/update-settings' || currentPathname === '/add-parameter' || currentPathname === '/view-parameter' || currentPathname === '/edit-parameter') {
          setSelected('/update-settings')
        }
        else if(currentPathname === '/update-commands' || currentPathname === '/add-command' || currentPathname === '/view-command' || currentPathname === '/edit-command'){
          setSelected('/update-commands')
        }
      }
      else if (chargingStationRoutes.includes(currentPathname)) {
        setSelected('/charging-stations')
        handleOpenedMenu(null)
      }
      else if (customerRoutes.includes(currentPathname)) {
        setSelected('/customers')
        handleOpenedMenu(null)
      }
      else if(accessControlRoutes.includes(currentPathname)){
        handleOpenedMenu('accessControl');
        if (currentPathname === '/manage-users' || currentPathname === '/user') {
          setSelected('/manage-users')
        } else if (currentPathname === '/roles' || currentPathname === '/role') {
          setSelected('/roles')
        } else if (currentPathname === '/manage-org' ||  currentPathname === '/org') {
          setSelected('/manage-org')
        }
      }
      else if (reportsRoutes.includes(currentPathname)) {
        handleOpenedMenu('reports');
        setSelected(currentPathname)
      }
      else if(configureNotificationRoutes.includes(currentPathname)){
        setSelected('/configureNotification')
        handleOpenedMenu(null)
      }
      else if(ruleEngineRoutes.includes(currentPathname)){
        setSelected('/rule-engine')
        handleOpenedMenu(null)
      }
      else if (updateCommandRoutes.includes(currentPathname)) {
        setSelected('/update-commands')
        handleOpenedMenu('Chargers')
      }
      else {
        handleOpenedMenu(null)
        setSelected(currentPathname)
      }
    }

  }, [location.pathname])


  const sideBarFeatures = [
    {
      text: 'Dashboard',
      path: '/dashboard',
      className: 'dashboard__icon',
      isAccessible : access?.dashboard?.view()
    },
    {
      text: 'Alarms',
      path: '/alarms',
      className: 'alarm__management__icon',
      isAccessible : access?.alarms?.view()
    },
    {
      text: 'Chargers',
      path: '/manage-chargers',
      parentLabel: 'Chargers',
      className: 'chargers__icon',
      nestedMenus: [
        {
          text: 'Manage Chargers',
          path: '/manage-chargers',
          className: 'manage__chargers__icon',
          parentLabel: 'Chargers',
          isAccessible : access?.chargers?.view()
        },
        {
          text: 'Update Settings',
          path: '/update-settings',
          className: 'set__parameters__icon',
          parentLabel: 'Chargers',
          isAccessible : access?.updateSetting?.view()
        },
        {
          text: 'Update Commands',
          path: '/update-commands',
          className: 'set__parameters__icon',
          parentLabel: 'Chargers',
          isAccessible : access?.updateSetting?.view()
        },
        // {
        //   text: 'Broadcast Message',
        //   path: '/broadcast-message',
        //   className: 'broadcast__message__icon',
        //   parentLabel: 'Chargers',
        // },
      ],
    },
    {
      text: 'Charging Stations',
      path: '/charging-stations',
      className: 'charging__stations__icon',
      isAccessible : access?.chargingStation?.view()
    },
    {
      text: 'Customers',
      path: '/customers',
      className: 'customer__management__icon',
      isAccessible : access?.customers?.view()
    },
    {
      text: 'Access Control',
      parentLabel: 'accessControl',
      path: '/manage-users',
      className: 'user__management__icon',
      nestedMenus: [
        {
          text: 'Organisations',
          path: '/manage-org',
          className: 'manage__org__icon',
          parentLabel: 'accessControl',
          isAccessible : access?.organisation?.view()
        },
        {
          text: 'Users',
          path: '/manage-users',
          className: 'manage__users__icon',
          parentLabel: 'accessControl',
          isAccessible : access?.users?.view()
        },
        {
          text: 'Roles',
          path: '/roles',
          className: 'role__management__icon',
          parentLabel: 'accessControl',
          isAccessible : access?.roles?.view()
        },
      ],
    },
    {
      text: 'Reports',
      parentLabel: 'reports',
      path: '/chargingHistory',
      className: 'reports__icon',
      nestedMenus : [
        {
          text: 'Charging History',
          path: '/chargingHistory',
          parentLabel: 'reports',
          isAccessible :access?.reports?.chargingHistory?.view()
        },
        {
          text: 'Charger Logs',
          path: '/chargerLogs',
          parentLabel: 'reports',
          isAccessible :access?.reports?.chargerLog?.status?.view() || access?.reports?.chargerLog?.setting?.view() || access?.reports?.chargerLog?.systemInfo?.view()
        },
        {
          text: 'Exception Logs',
          path: '/exceptionLogs',
          parentLabel: 'reports',
          isAccessible : access?.reports?.exceptionLogs?.view()
        },
      ]
    },
    // {
    //   text: 'Rule Engine',
    //   parentLabel: 'ruleEngine',
    //   path: '/rule-engine',
    //   className: 'rule__engine__icon',
    //   isAccessible : access?.ruleEngine?.ruleEngine?.view()
    //   // nestedMenus : [
    //   //   {
    //   //     text: 'All Rules',
    //   //     path: '/rule-engine',
    //   //     parentLabel: 'ruleEngine',
    //   //     isAccessible : access?.reports?.chargingHistory?.view()
    //   //   },
    //   //   {
    //   //     text: 'Alarms',
    //   //     path: '/ruleEngineAlarms',
    //   //     parentLabel: 'ruleEngine',
    //   //     isAccessible :access?.reports?.chargerLog?.status?.view() || access?.reports?.chargerLog?.setting?.view() || access?.reports?.chargerLog?.systemInfo?.view()
    //   //   },
    //   //   {
    //   //     text: 'Anomaly',
    //   //     path: '/ruleEngineAnomaly',
    //   //     parentLabel: 'ruleEngine',
    //   //     isAccessible : access?.reports?.exceptionLogs?.view()
    //   //   },
    //   //   {
    //   //     text: 'Auto Alarms',
    //   //     path: '/ruleEngineAutoAlarms',
    //   //     parentLabel: 'ruleEngine',
    //   //     isAccessible : access?.reports?.exceptionLogs?.view()
    //   //   },
    //   // ]
    // },
    // {
    //   text: 'Notifications',
    //   path: '/configureNotification',
    //   className: 'configure__notification__icon',
    //   isAccessible : access?.configureNotifications?.singleConfigureNotification?.History?.view() || access?.configureNotifications?.singleConfigureNotification?.summary?.view()
    // }
  ];

  const filteredSideBarItems = sideBarFeatures?.filter((item) => {
    if (item?.nestedMenus && item?.nestedMenus?.length > 0 ) {
      item.nestedMenus = item?.nestedMenus?.filter((nested) => nested?.isAccessible)
    }
    return item?.isAccessible || item?.nestedMenus?.length
  })

  const drawer = (
    <List component="nav" style={{ borderRight: '0px', padding: 0 }}>
      {filteredSideBarItems?.map((feature, index) => (
        <SidebarItem
          toggleOpen={openMenu}
          handleOpenedMenu={handleOpenedMenu}
          selected={selected}
          handleSelect={handleSelectedMenu}
          handleDrawerClose={handleDrawerClose}
          {...feature}
          key={index}
        />
      ))}
    </List>
  );

  return (
    <ThemeProvider theme={SidebarTheme}>
      <Drawer
        variant="permanent"
        className="app__sidebar"
        ModalProps={{
          keepMounted: true,
        }}
        open={expandSideBar}
        onClose={handleDrawerClose}
        sx={{
          display: screenSize?.width <= 800 && !expandSideBar ? 'none' : 'block',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 230,
            borderRight: 0,
            boxShadow:
              '0px 6px 4px -2px rgba(0,0,0,0.005), 0px 8px 6px 3px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.05)',
          },
        }}
      >
        {drawer}
        <div className='app__version'>

          {localStorage?.getItem('spin-rms-app-version') !== null && 
            moment(new Date(localStorage?.getItem('spin-rms-app-version') as string)).format('lll')}</div>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
