/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureNotificationTypes } from '../reduxExports'
import hwaxios from '../api-service/HWAxios';
import rmsStore from '../rmsStore';
import { AxiosError, isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const url = process.env.REACT_APP_SERVER_BASE_URL

// TODO: Configure Notification List API
export const fetchConfigureNotificationData: any = (reqBody: any) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LOADER,
      configureNotificationTableLoader: true
    }
    dispatch(loaderStartAction);
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_NOTIFICATION_LIST_REQUEST_HASH,
      configureNotificationListRequestHash: searchHash
    }
    dispatch(hashAction)
    const response = await hwaxios.post(url + '/alarm-notification/list', reqBody)
    if (searchHash === rmsStore.getState().configureNotificationReducer.configureNotificationListRequestHash) {
      const action: Partial<ConfigureNotificationAction> = {
        type: configureNotificationTypes.FETCH_CONFIGURE_NOTIFICATIONS,
        configureNotification: response.data?.data?.response,
        configureNotificationCount: response.data?.data?.count,
      }
      dispatch(action)
    }
    if (response?.status === 200) {
      const loaderEndAction: Partial<ConfigureNotificationAction> = {
        type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LOADER,
        configureNotificationTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LOADER,
      configureNotificationTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}
//Configure Notification Single Row Enable/Disable API

export const setAlarmRuleStatus: any = (reqBody: any) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_STATUS_LOADER,
      configureNotificationTableLoader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.put(url + '/alarm-notification/status', reqBody)
    const action: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_RULE_STATUS,

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ConfigureNotificationAction> = {
        type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_STATUS_LOADER,
        configureNotificationTableLoader: false
      }
      dispatch(loaderEndAction);
      toast.success(`Notification ${response.data.data.status}`);
    }
  } catch (error) {
    const loaderEndAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_STATUS_LOADER,
      configureNotificationTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    if (error instanceof AxiosError) {
      if (error.response) {
        toast.error(error.response.data)
      }
    }
  }
}

export const setConfigurationNotificationListTableProps: any = (data) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => void): Promise<void> => {
  const action: Partial<ConfigureNotificationAction> = {
    type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LIST_TABLE_PROPS,
    configureNotificationListTableProps: data,
  };
  dispatch(action);
}

export const createAlarmRule: any = (reqBody: any) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => any): Promise<any> => {
  try {
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_NOTIFICATION_LIST_REQUEST_HASH,
      configureNotificationListRequestHash: searchHash
    }

    dispatch(hashAction)
    const response = await hwaxios.post(url + '/alarm-notification', reqBody)
    if (searchHash === rmsStore.getState().configureNotificationReducer.configureNotificationListRequestHash) {
      const action: Partial<ConfigureNotificationAction> = {
        type: configureNotificationTypes.CREATE_NEW_RULE,
      }
      dispatch(action)
    }
    if (response?.status === 200) {
      console.log('*** New Notification Successfully Created ***')
    }

    return response
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        console.log(error.response);
        toast.error(error.response.data)
      }
    }
  }
}

export const updateAlarmRule: any = (reqBody: any) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => any): Promise<any> => {
  try {
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_NOTIFICATION_LIST_REQUEST_HASH,
      configureNotificationListRequestHash: searchHash
    }
    dispatch(hashAction)
    const response = await hwaxios.put(url + '/alarm-notification', reqBody)
    console.log(response, 'mnb')

    if (searchHash === rmsStore.getState().configureNotificationReducer.configureNotificationListRequestHash) {
      const action: Partial<ConfigureNotificationAction> = {
        type: configureNotificationTypes.EDIT_NEW_RULE,
      }
      dispatch(action)
    }
    if (response?.status === 200) {
      console.log('***  Notification Successfully Updated ***')
    }

    return response
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        console.log(error.response);
        toast.error(error.response.data)
      }
    }
  }
}

export const fetchSingleRule: any = (reqBody: any, id: string) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => any): Promise<any> => {
  try {
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_NOTIFICATION_LIST_REQUEST_HASH,
      configureNotificationListRequestHash: searchHash
    }
    dispatch(hashAction)
    const response = await hwaxios.get(url + '/alarm-notification' + `?id=${id}`, reqBody)
    if (searchHash === rmsStore.getState().configureNotificationReducer.configureNotificationListRequestHash) {
      const action: Partial<ConfigureNotificationAction> = {
        type: configureNotificationTypes.FETCH_RULE_FOR_EDIT,
        individualAlarmDetails: response?.data?.data,
      }
      dispatch(action)
    }
    if (response?.status === 200) {
      console.log('***  Notification Succesfully fetched  *****')
    }
    return response
  } catch (error) {
    console.error(error)
    if (error instanceof AxiosError) {
      if (error.response) {
        console.log(error.response);
        toast.error(error.response.data)
      }
    }
  }
}
//deleting a rule
export const deleteConfigureNotification: any = (id: string) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + `/alarm-notification?id=${id}`,)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// configure notification history list api
export const fetchConfigureNotificationHistoryData: any = (reqBody: any) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LOADER,
      configureNotificationTableLoader: true
    }
    dispatch(loaderStartAction);
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_NOTIFICATION_HISTORY_LIST_REQUEST_HASH,
      configureNotificationHistoryListRequestHash: searchHash
    }
    dispatch(hashAction)
    const response = await hwaxios.post(url + '/alarm-notification/history', reqBody)
    if (searchHash === rmsStore.getState().configureNotificationReducer.configureNotificationHistoryListRequestHash) {
      const action: Partial<ConfigureNotificationAction> = {
        type: configureNotificationTypes.FETCH_CONFIGURE_NOTIFICATIONS_HISTORY,
        configureNotificationHistory: response.data?.data?.response,
        configureNotificationHistoryCount: response.data?.data?.count,
      }
      dispatch(action)
    }
    if (response?.status === 200) {
      const loaderEndAction: Partial<ConfigureNotificationAction> = {
        type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LOADER,
        configureNotificationTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LOADER,
      configureNotificationTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setConfigurationNotificationHistoryListTableProps: any = (data) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => void): Promise<void> => {
  const action: Partial<ConfigureNotificationAction> = {
    type: configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_HISTORY_LIST_TABLE_PROPS,
    configureNotificationHistoryListTableProps: data,
  };
  dispatch(action);
}

export const fetchNotificationHistoryTimelineData: any = (reqBody: any) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => void): Promise<void> => {
  try {
    const response = await hwaxios.post(url + '/alarm-notification/timeline', reqBody)
    const action: Partial<ConfigureNotificationAction> = {
      type: configureNotificationTypes.FETCH_CONFIGURE_NOTIFICATION_HISTORY_TIMELINE,
      configureNotificationHistoryTimeline: response.data?.data,
    }
    dispatch(action)
  } catch (error) {
    console.error(error)
    throw error
  }
}


export const fetchNotificationTemplate = async (notificationId: string) => {
  try {
    const response = await hwaxios.get(url + `/alarm-notification/history/preview?id=${notificationId}`);
    if (response?.status === 200 || response?.status === 201) {
      return { status: 'SUCCESS', data: response?.data?.data };
    }
  } catch (error) {
    return { status: 'FAILURE' };
  }
}

export const setLatestConfigurationOnTop: any = (data) => async (dispatch: (arg0: Partial<ConfigureNotificationAction>) => void): Promise<void> => {
  const action: Partial<ConfigureNotificationAction> = {
    type: configureNotificationTypes.SET_LATEST_CONFIGURATION_ON_TOP,
    getLatestConfigurationOnTop: data,
  };
  dispatch(action);
}
